<template>
  <div class="productOverall">
    <div class="title">
      <h2>Product Overall</h2>
    </div>
    <div class="bgf0">
      <el-row type="flex">
        <el-col :span="24" style="padding:16px;">
          <el-card shadow="hover" class="pad30">
            <el-form :inline="true" :model="filter" label-width="140px">
              <el-form-item label="Package Name">
                <el-input v-model="filter.prod"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button :disabled="searchDisbled" type="primary" @click="searchClick(1)"
                  >Search</el-button
                >
              </el-form-item>
            </el-form>
          </el-card>
          <el-card shadow="hover">
            <el-table :data="prodList" border stripe style="width:100%;" height="70vmin">
              <el-table-column label="Prod Id" prop="id"></el-table-column>
              <el-table-column label="Package Name">
                <template slot-scope="scope">
                  <span class="cor337ab7" @click="prodDetail(scope.row)">{{ scope.row.prod }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Aff Black Status">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.affBlacklistSwitch == true" type="success">已拉黑</el-tag>
                  <el-tag v-else type="danger">未拉黑</el-tag>
                </template>
              </el-table-column>
              <!-- <el-table-column
                label="Prod Name"
                prop="name"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                label="Description"
                prop="description"
                show-overflow-tooltip
              ></el-table-column> -->
            </el-table>
            <pagination
              class="block pagePanel"
              :pageTotal="total"
              @handleSizeChange="handleSizeChange"
              @handleCurrentChange="handleCurrentChange"
              :pageSize="pageSize"
              :currentPage="currentPage"
            ></pagination>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
  import { getProdList } from 'api/product/overall';
  import Pagination from '@/components/pagination';
  export default {
    components: {
      Pagination,
    },
    data() {
      return {
        filter: {
          prod: null,
        },
        searchDisbled: false,
        total: null,
        pageSize: 10,
        currentPage: 1,
        prodList: [],
      };
    },
    mounted() {},
    methods: {
      searchClick(curPage) {
        if (curPage) {
          this.currentPage = curPage;
        }
        const param = {
          ...this.filter,
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        this.searchDisbled = true;
        getProdList(param).then((response) => {
          this.searchDisbled = false;
          if (response.code === 200) {
            this.prodList = response.result;
            this.total = response.total;
          } else {
            this.$message.error('Error:' + response.message);
          }
        });
      },
      handleSizeChange(size) {
        this.pageSize = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(currentPage) {
        this.currentPage = currentPage;
        this.searchClick();
      },
      prodDetail(row) {
        this.$router.push({
          path: '/product/detail',
          query: {
            prodId: row.id,
            packageName: row.prod,
            affBlacklistSwitch: row.affBlacklistSwitch,
          },
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .cor337ab7 {
    color: #337ab7;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
</style>
